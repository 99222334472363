/**
 * init dst-components
 */
export async function init(): Promise<void> {
	const isApp = Boolean(window.DERSTANDARD?.pageConfig?.appInfo);

	if (isApp) {
		void (await import('./components/DstHeaderApp/DstHeaderApp.webcomponent.svelte')).default;
		const header = document.querySelector('dst-header');
		const headerAttributes = header.attributes;
		const headerChildren = header.childNodes;
		const appHeader = document.createElement('dst-header-app');

		header.classList.add('dst-header-component');
		header.insertAdjacentElement('afterend', appHeader);
		[...headerAttributes].forEach((attr) => appHeader.setAttribute(attr.name, attr.value));
		[...headerChildren].forEach((node) => appHeader.appendChild(node));
		header.parentNode.removeChild(header);
	} else {
		void (await import('./components/DstHeader/DstHeader.webcomponent.svelte')).default;
	}

	const isStory = window.DERSTANDARD?.pageConfig?.pageType === 'story';

	if (isStory) {
		void (await import('./components/DstCommunityReactions/DstCommunityReactions.webcomponent.svelte')).default;
	}
}
